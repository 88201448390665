// https://codesandbox.io/s/custom-cursor-with-framer-motion-n6i55?file=/src/index.js:0-2751
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import useMouse from "@react-hook/mouse-position";
import { motion, useTransform, type Variants } from "framer-motion";
import useMousePosition from "@hooks/useMousePosition";
import { useThrottle } from "react-use";
import { cMerge } from "@utils/cMerge";


const CrossHair = React.forwardRef<SVGElement, React.ComponentPropsWithRef<"svg">>(
  ({className, fill, children}, ref) => {
    return (
      <svg className={cMerge(className)} fill={fill || "var(--color-accent)"} viewBox="0 0 32 32">
          <g className="top-left" data-svg-origin="0 0">
              <path d="M4 0H0v4h1V1h3V0Z"></path>
          </g>
          <g className="top-right" data-svg-origin="28 0">
              <path d="M28 1V0h4v4h-1V1h-3Z"></path>
          </g>
          <g className="bottom-right" data-svg-origin="28 28">
              <path d="M28 31h3v-3h1v4h-4v-1Z"></path>
          </g>
          <g className="bottom-left" data-svg-origin="0 28">
              <path d="M1 28v3h3v1H0v-4h1Z"></path>
          </g>
          <circle cx="16" cy="16" r="2"></circle>
      </svg>      
    )
  }
)



export function Cursor() {
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0,
    });
    const mpThrottled = useThrottle(mousePosition, 30);

    useEffect(() => {
        const updateMousePosition = (e: MouseEvent) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY,
            });
        };

        window.addEventListener("mousemove", updateMousePosition);

        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);

    const variants: Variants = {
        default: {
            x: mpThrottled.x - 16,
            y: mpThrottled.y - 16,
        },
    };

    const cursorCrosshair = (
        <motion.span
            className={`fixed top-0 left-0 w-8 h-8 block pointer-events-none translate-x-4 translate-y-4 z-50 mix-blend-difference`}
            variants={variants}
            // animate={animate}
            animate="default"
            transition={{
                x: {
                    duration: 0.3,
                    ease: "linear",
                    repeat: 0,
                    type: "spring",
                    stiffness: 80,
                },
                y: {
                    duration: 0.3,
                    ease: "linear",
                    repeat: 0,
                    type: "spring",
                    stiffness: 80,
                },
                default: {
                    duration: 2.5,
                    repeat: Infinity,
                },
            }}
        >
          <CrossHair />
        </motion.span>
    );

    const cursorCrosshairShadow = (
      <motion.span
          className={`fixed top-0 left-0 w-8 h-8 block pointer-events-none translate-x-4 translate-y-4 opacity-50 z-40 mix-blend-difference`}
          variants={variants}
          animate="default"
          transition={{
              x: {
                  duration: 0.3,
                  ease: "linear",
                  repeat: 0,
                  type: "spring",
                  stiffness: 71,
              },
              y: {
                  duration: 0.3,
                  ease: "linear",
                  repeat: 0,
                  type: "spring",
                  stiffness: 71,
              },
              default: {
                  duration: 2.5,
                  repeat: Infinity,
              },
          }}
      >
        <CrossHair fill="var(--color-text)" />
      </motion.span>
  );

    return <>{cursorCrosshair}{cursorCrosshairShadow}</>;
}
